import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function ArrowIcon(props, ref) {
    return (
      <svg
        width="289"
        height="386"
        viewBox="0 0 289 386"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        {...props}
        ref={ref}
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M257.5 0.749999C250.293 0.499741 243.212 2.69107 237.406 6.96875L13.4062 166.969C9.26531 169.93 5.89061 173.837 3.56241 178.364C1.23421 182.892 0.0196838 187.909 0.0196838 193C0.0196838 198.091 1.23421 203.108 3.56241 207.636C5.89061 212.163 9.26531 216.07 13.4062 219.031L237.406 379.031C240.816 381.619 244.71 383.496 248.859 384.551C253.007 385.607 257.325 385.819 261.557 385.177C265.788 384.534 269.848 383.049 273.496 380.809C277.144 378.57 280.305 375.621 282.793 372.138C285.281 368.655 287.045 364.708 287.98 360.531C288.916 356.354 289.004 352.032 288.24 347.82C287.475 343.609 285.873 339.593 283.53 336.012C281.186 332.43 278.147 329.355 274.594 326.969L87.0312 193L274.594 59.0312C280.117 55.2014 284.293 49.7299 286.532 43.3929C288.771 37.0559 288.958 30.1749 287.066 23.7257C285.175 17.2766 281.301 11.5866 275.994 7.46262C270.687 3.33868 264.217 0.990126 257.5 0.749999Z"
        />
      </svg>
    );
  },
);
