import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function LocationIcon(props, ref) {
    return (
      <svg
        width="416"
        height="416"
        viewBox="0 0 416 416"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M208 0C137.312 0 80 57.312 80 128C80 224 192 256 192 320C192 328.832 199.168 336 208 336C216.832 336 224 328.832 224 320C224 256 336 224 336 128C336 57.312 278.688 0 208 0ZM208 96C225.68 96 240 110.32 240 128C240 145.68 225.68 160 208 160C190.32 160 176 145.68 176 128C176 110.32 190.32 96 208 96ZM118.094 263.312C51.6137 274.672 0 299.136 0 336C0 388.512 104.64 416 208 416C311.36 416 416 388.512 416 336C416 299.136 364.386 274.672 297.906 263.312C296.61 264.608 295.296 265.892 294 267.156C285.248 275.78 277.107 283.883 270.562 291.562C344.354 300.25 384 323.008 384 336C384 352.304 321.872 384 208 384C94.128 384 32 352.304 32 336C32 323.008 71.6455 300.25 145.438 291.562C138.878 283.883 130.736 275.78 122 267.156C120.72 265.892 119.406 264.608 118.094 263.312Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
