import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

export interface SectionWithContainerProps
  extends HTMLAttributes<HTMLDivElement> {
  containerProps?: HTMLAttributes<HTMLDivElement>;
  withPadding?: boolean;
}
const SectionWithContainer: FC<SectionWithContainerProps> = ({
  withPadding = true,
  children,
  containerProps,
  className,
  ...props
}) => {
  return (
    <section {...props} className={cx(className, { 'py-12': withPadding })}>
      <div
        {...containerProps}
        className={cx('container mx-auto px-5', containerProps?.className)}
      >
        {children}
      </div>
    </section>
  );
};

export default SectionWithContainer;
