import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function DriveIcon(props, ref) {
    return (
      <svg
        width="416"
        height="353"
        viewBox="0 0 416 353"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M32 1.00001C29.8798 0.970028 27.7748 1.36173 25.8073 2.15235C23.8398 2.94297 22.0491 4.11674 20.5392 5.60545C19.0293 7.09416 17.8303 8.86812 17.0119 10.8242C16.1935 12.7804 15.7721 14.8796 15.7721 17C15.7721 19.1204 16.1935 21.2197 17.0119 23.1758C17.8303 25.1319 19.0293 26.9059 20.5392 28.3946C22.0491 29.8833 23.8398 31.0571 25.8073 31.8477C27.7748 32.6383 29.8798 33.03 32 33H40.375L20.2812 83.2813C8.41529 87.9613 0 99.4663 0 113V169.813C0 183.653 8.87925 195.915 22.0312 200.219L48.8125 209C52.5245 190.744 68.64 177 88 177C107.36 177 123.476 190.744 127.188 209H288.812C292.525 190.744 308.671 177 328.031 177C347.391 177 363.507 190.744 367.219 209H383.219C400.003 209 416 192.025 416 184.281V130.531C416 115.523 405.594 102.497 390.938 99.2813C377.126 96.246 358.697 92.5021 339.125 88.8438L304.312 36.625C289.497 14.3715 264.483 1.00001 237.75 1.00001H112H32ZM74.8438 33H112H144V81H55.625L74.8438 33ZM176 33H237.75C253.833 33 268.791 41.0125 277.688 54.375L295.812 81.5313C294.737 81.3763 293.631 81.1493 292.562 81H176V33ZM88 241C57.2616 241 32 266.262 32 297C32 327.738 57.2616 353 88 353C110.13 353 129.243 339.805 138.312 321H180.312C183.119 325.861 187.155 329.898 192.014 332.706C196.874 335.515 202.387 336.996 208 337C213.608 336.99 219.114 335.507 223.968 332.699C228.822 329.891 232.852 325.856 235.656 321H277.688C286.757 339.805 305.87 353 328 353C358.738 353 384 327.738 384 297C384 266.262 358.738 241 328 241C300.001 241 276.771 262.033 272.812 289H235.688C232.881 284.139 228.845 280.102 223.986 277.294C219.126 274.485 213.613 273.004 208 273C202.392 273.01 196.886 274.493 192.032 277.301C187.178 280.109 183.148 284.144 180.344 289H143.188C139.229 262.033 115.999 241 88 241ZM88 273C101.444 273 112 283.556 112 297C112 310.444 101.444 321 88 321C74.5556 321 64 310.444 64 297C64 283.556 74.5556 273 88 273ZM328 273C341.444 273 352 283.556 352 297C352 310.444 341.444 321 328 321C314.556 321 304 310.444 304 297C304 283.556 314.556 273 328 273Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
