import { FC, HTMLAttributes, ReactNode } from 'react';

import cx from 'classnames';

export interface VehicleDetailIconProps extends HTMLAttributes<HTMLDivElement> {
  icon?: ReactNode;
  label?: string;
}
const VehicleDetailIcon: FC<VehicleDetailIconProps> = ({
  icon,
  label,
  className,
  ...props
}) => {
  return (
    <div {...props} className={cx(className, 'flex flex-col items-center')}>
      <div className="flex flex-col items-center justify-center w-[65px] h-[65px] border rounded-full border-blue-20">
        {icon}
      </div>
      <h3 className="mt-3 text-base text-center text-dark-blue-100">{label}</h3>
    </div>
  );
};

export default VehicleDetailIcon;
