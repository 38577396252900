import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function BodyIcon(props, ref) {
    return (
      <svg
        width="480"
        height="289"
        viewBox="0 0 480 289"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M191.75 0.781209C190.975 0.798008 190.202 0.871117 189.438 0.999959H144C127.374 0.999959 109.516 7.95118 93.375 24.2187C77.8768 39.8382 62.0875 63.9117 42.0938 101.156C17.6522 110.18 0 133.524 0 161V193C0 219.512 21.488 241 48 241H48.8125C52.7708 267.967 76.0011 289 104 289C131.999 289 155.229 267.967 159.188 241H336.812C340.771 267.967 364.001 289 392 289C419.999 289 443.229 267.967 447.188 241H448C465.68 241 480 226.68 480 209V177C471.168 177 464 169.832 464 161C464 155.32 467.13 150.551 471.594 147.719C458.416 130.649 427.12 102.856 363.812 98C340.051 61.9165 321.161 38.4173 305.062 23.8125C286.806 7.24957 270.038 0.999959 256 0.999959H194.469C193.57 0.849798 192.661 0.776615 191.75 0.781209ZM144 33H176V97H80.9375C95.221 72.2486 106.817 56.0991 116.094 46.75C128.053 34.6975 134.402 33 144 33ZM208 33H256C262.01 33 269.003 34.2909 283.562 47.5C293.989 56.9594 307.579 72.8563 324.562 97H208V33ZM104 209C117.444 209 128 219.556 128 233C128 246.444 117.444 257 104 257C90.5556 257 80 246.444 80 233C80 219.556 90.5556 209 104 209ZM392 209C405.444 209 416 219.556 416 233C416 246.444 405.444 257 392 257C378.556 257 368 246.444 368 233C368 219.556 378.556 209 392 209Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
