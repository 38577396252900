import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function CheckIcon(props, ref) {
    return (
      <svg
        className="w-10 h-10"
        viewBox="0 0 402 289"
        fill="currentColor"
        {...props}
        ref={ref}
      >
        <path d="M384.687 0.843748C380.531 0.967606 376.585 2.70487 373.687 5.6875L129 250.375L28.3125 149.688C26.8382 148.152 25.0723 146.926 23.1182 146.081C21.1642 145.237 19.0612 144.791 16.9326 144.769C14.8039 144.747 12.6924 145.151 10.7215 145.955C8.75068 146.76 6.96021 147.95 5.45495 149.455C3.94968 150.96 2.7599 152.751 1.95528 154.722C1.15065 156.692 0.747356 158.804 0.769013 160.933C0.79067 163.061 1.23684 165.164 2.0814 167.118C2.92596 169.072 4.15193 170.838 5.6875 172.312L117.688 284.312C120.688 287.312 124.757 288.997 129 288.997C133.243 288.997 137.312 287.312 140.312 284.312L396.312 28.3125C398.625 26.0651 400.204 23.1716 400.843 20.0113C401.482 16.851 401.152 13.5713 399.895 10.6019C398.639 7.63252 396.514 5.11205 393.801 3.37079C391.087 1.62952 387.91 0.748702 384.687 0.843748Z" />
      </svg>
    );
  },
);
