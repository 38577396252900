import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function SeatIcon(props, ref) {
    return (
      <svg
        width="384"
        height="384"
        viewBox="0 0 384 384"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M192 0.03125C124.128 0.03125 96 43.5672 96 96.0312C96 113.695 104.438 131.438 104.438 131.438C101.046 133.389 95.467 139.586 96.875 150.594C99.499 171.122 108.399 176.349 114.062 176.781C116.223 195.933 136.8 220.432 144 224V256C142.752 259.76 140.621 262.885 138.125 265.781L320 384H384C384 272 256 304 240 256V224C247.2 220.432 267.777 195.933 269.938 176.781C275.602 176.349 284.501 171.122 287.125 150.594C288.533 139.57 282.954 133.389 279.562 131.438C279.562 131.438 288 115.407 288 96.0312C288 57.1833 272.752 24.0312 240 24.0312C240 24.0312 228.624 0.03125 192 0.03125ZM138.125 265.781L0 176V217.344L103.281 284.469C118.033 279.781 130.573 274.453 138.125 265.781ZM103.281 284.469C60.2892 298.101 0 307.136 0 384H256.406L103.281 284.469Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
