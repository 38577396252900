import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function FuelIcon(props, ref) {
    return (
      <svg
        width="401"
        height="353"
        viewBox="0 0 401 353"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M49 0C31.32 0 17 14.32 17 32V320C14.8798 319.97 12.7748 320.362 10.8073 321.152C8.83979 321.943 7.04905 323.117 5.53914 324.605C4.02922 326.094 2.83025 327.868 2.01188 329.824C1.19352 331.78 0.772095 333.88 0.772095 336C0.772095 338.12 1.19352 340.22 2.01188 342.176C2.83025 344.132 4.02922 345.906 5.53914 347.395C7.04905 348.883 8.83979 350.057 10.8073 350.848C12.7748 351.638 14.8798 352.03 17 352H241C243.12 352.03 245.225 351.638 247.193 350.848C249.16 350.057 250.951 348.883 252.461 347.395C253.971 345.906 255.17 344.132 255.988 342.176C256.806 340.22 257.228 338.12 257.228 336C257.228 333.88 256.806 331.78 255.988 329.824C255.17 327.868 253.971 326.094 252.461 324.605C250.951 323.117 249.16 321.943 247.193 321.152C245.225 320.362 243.12 319.97 241 320V256H289V296C289 326.739 314.261 352 345 352C346.984 351.966 348.944 351.563 350.781 350.812C378.712 347.755 401 324.693 401 296V160C400.999 155.757 399.313 151.688 396.312 148.688L332.312 84.6875C331.568 83.9422 330.751 83.2723 329.875 82.6875L281.875 50.6875C279.217 48.8613 276.069 47.881 272.844 47.875C269.388 47.8689 266.023 48.9817 263.253 51.047C260.482 53.1122 258.454 56.0189 257.473 59.3324C256.491 62.6458 256.609 66.1879 257.808 69.4289C259.007 72.6699 261.223 75.4357 264.125 77.3125L305 104.562V144C305 161.68 319.32 176 337 176H369V296C369 309.453 358.453 320 345 320C331.547 320 321 309.453 321 296V256C321 238.511 306.489 224 289 224H241V32C241 14.32 226.68 0 209 0H49ZM49 32H209V128H49V32Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
