import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function InfoIcon(props, ref) {
    return (
      <svg
        width="384"
        height="384"
        viewBox="0 0 384 384"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={ref}
      >
        <path
          d="M192 0C85.968 0 0 85.968 0 192C0 298.032 85.968 384 192 384C298.032 384 384 298.032 384 192C384 85.968 298.032 0 192 0ZM208 288H176V176H208V288ZM192 136C178.752 136 168 125.248 168 112C168 98.752 178.752 88 192 88C205.248 88 216 98.752 216 112C216 125.248 205.248 136 192 136Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
