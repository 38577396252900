import { forwardRef, SVGProps } from 'react';

export default forwardRef<SVGSVGElement, SVGProps<SVGElement>>(
  function PageNotFoundIcon(props, ref) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 140 99"
        fill="none"
        {...props}
        ref={ref}
      >
        <path
          xmlns="http://www.w3.org/2000/svg"
          d="M11.6667 0C5.29047 0 0 5.27716 0 11.6373V34.9119C0 41.2721 5.29047 46.5492 11.6667 46.5492H16.4746C16.8134 46.6058 17.1565 46.6324 17.5 46.6288V93.0985C17.4891 93.8695 17.6319 94.635 17.9201 95.3506C18.2084 96.0661 18.6363 96.7173 19.1791 97.2664C19.7218 97.8155 20.3686 98.2515 21.0818 98.5491C21.7949 98.8467 22.5603 99 23.3333 99C24.1064 99 24.8717 98.8467 25.5849 98.5491C26.2981 98.2515 26.9448 97.8155 27.4876 97.2664C28.0304 96.7173 28.4583 96.0661 28.7465 95.3506C29.0348 94.635 29.1776 93.8695 29.1667 93.0985V46.5492H51.4746C52.1083 46.6544 52.755 46.6544 53.3887 46.5492H86.4746C87.1083 46.6544 87.755 46.6544 88.3887 46.5492H110.833V69.8239H40.8333V81.4612H110.833V93.0985C110.822 93.8695 110.965 94.635 111.253 95.3506C111.542 96.0661 111.97 96.7173 112.512 97.2664C113.055 97.8155 113.702 98.2515 114.415 98.5491C115.128 98.8467 115.894 99 116.667 99C117.44 99 118.205 98.8467 118.918 98.5491C119.631 98.2515 120.278 97.8155 120.821 97.2664C121.364 96.7173 121.792 96.0661 122.08 95.3506C122.368 94.635 122.511 93.8695 122.5 93.0985V46.5492H128.333C134.71 46.5492 140 41.2721 140 34.9119V11.6373C140 5.27716 134.71 0 128.333 0C89.5482 0 50.5635 0 11.6667 0ZM11.6667 11.6373H38.418L15.0846 34.9119H11.6667V11.6373ZM54.9154 11.6373H73.418L50.0846 34.9119H31.582L54.9154 11.6373ZM89.9154 11.6373H108.418L85.0846 34.9119H66.582L89.9154 11.6373ZM124.915 11.6373H128.333V34.9119H101.582L124.915 11.6373Z"
          fill="#F05323"
        />
      </svg>
    );
  },
);
