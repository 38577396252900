import { FC, HTMLAttributes } from 'react';

import cx from 'classnames';

export interface CardProps extends HTMLAttributes<HTMLDivElement> {
  // topLeft, topRight, bottomRight, bottomLeft
  borderRadius?: [
    '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | false,
    '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | false,
    '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | false,
    '4xl' | '3xl' | '2xl' | 'xl' | 'lg' | 'md' | 'sm' | false,
  ];
  withShadow?: boolean;
  withPadding?: boolean;
}
const Card: FC<CardProps> = ({
  children,
  className,
  borderRadius = [false, false, false, false],
  withShadow = false,
  withPadding = false,
  ...props
}) => {
  const [topLeft, topRight, bottomRight, bottomLeft] = borderRadius;
  return (
    <div
      {...props}
      className={cx(className, {
        'p-6': withPadding,
        'shadow-xl': withShadow,
        'rounded-tl-4xl': topLeft === '4xl',
        'rounded-tr-4xl': topRight === '4xl',
        'rounded-br-4xl': bottomRight === '4xl',
        'rounded-bl-4xl': bottomLeft === '4xl',
        'rounded-tl-3xl': topLeft === '3xl',
        'rounded-tr-3xl': topRight === '3xl',
        'rounded-br-3xl': bottomRight === '3xl',
        'rounded-bl-3xl': bottomLeft === '3xl',
        'rounded-tl-2xl': topLeft === '2xl',
        'rounded-tr-2xl': topRight === '2xl',
        'rounded-br-2xl': bottomRight === '2xl',
        'rounded-bl-2xl': bottomLeft === '2xl',
        'rounded-tl-xl': topLeft === 'xl',
        'rounded-tr-xl': topRight === 'xl',
        'rounded-br-xl': bottomRight === 'xl',
        'rounded-bl-xl': bottomLeft === 'xl',
        'rounded-tl-lg': topLeft === 'lg',
        'rounded-tr-lg': topRight === 'lg',
        'rounded-br-lg': bottomRight === 'lg',
        'rounded-bl-lg': bottomLeft === 'lg',
        'rounded-tl-md': topLeft === 'md',
        'rounded-tr-md': topRight === 'md',
        'rounded-br-md': bottomRight === 'md',
        'rounded-bl-md': bottomLeft === 'md',
        'rounded-tl-sm': topLeft === 'sm',
        'rounded-tr-sm': topRight === 'sm',
        'rounded-br-sm': bottomRight === 'sm',
        'rounded-bl-sm': bottomLeft === 'sm',
      })}
    >
      {children}
    </div>
  );
};

export default Card;
